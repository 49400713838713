<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="12"
        sm="12"
        order="1"
        class="align-self-end"
      >
        <h3>Dashboard</h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  data() {
    return {
      perfil: {},
    }
  },
}
</script>
